
















































































import zxcvbn from 'zxcvbn';
import { copyTextToClipboard } from '@/services/Copy';
import generator from 'generate-password';

export default {
  data() {
    return {
      loading: false,
      colorCard: 'primary',
      password: '',
      response: null,
    };
  },
  mounted() {
    if (this.$route.query.pass) {
      this.password = this.$route.query.pass;
      this.updatePass(this.password);
    }
  },
  methods: {
    copy(response: any) {
      let text = '';
      let isText = false;
      if (typeof response === 'string') {
        text = response;
        isText = true;
      } else {
        text = JSON.stringify(response);
      }
      const res = copyTextToClipboard(text);
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        if (isText) {
          this.$copied(`<p>Password <strong>${text}</strong> copied to clipboard</p>`);
        } else {
          this.$copied(`<p>Response copied to clipboard</p>`);
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err, text);
      }
    },
    stringify(object) {
      return JSON.stringify(object, null, '\t');
    },
    generateSecure() {
      var password = generator.generate({
        length: 20,
        numbers: true,
      });
      this.password = password;
      this.updatePass(password);
    },
    updatePass(newVal: string) {
      if (newVal) {
        this.loading = true;
        const res = zxcvbn(newVal);
        this.response = res;
        this.$nextTick(() => {
          this.loading = false;
        });
      } else {
        this.response = null;
      }
    },
  },
  computed: {
    colorWarning() {
      if (this.response) {
        const { score } = this.response;
        const sNumber = parseFloat(score);
        if (sNumber === 0) return 'red darken-3';
        if (sNumber < 4) return 'red darken-2';
      }
      return 'sideBarBlue';
    },
  },
};
